<script setup>
    import ONavbar from 'o365.vue.components.ONavbar.vue';
    import { getDataObjectById, getOrCreateProcedure, getOrCreateDataObject } from 'o365.vue.ts';
    import OOrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue';
    import { ref, computed, watch, onMounted, isProxy, toRaw } from 'vue';
    import context from 'o365.modules.Context.ts';
    import $t from "o365.modules.translate.ts";
    import BIMViewer from 'lease.vue.components.BIMviewer.vue';
    import { ToastType } from 'o365.modules.ToastService.ts';
    import alert from 'o365.controls.alert.ts';
    import ObjectLookup from "assets.vue.components.ObjectLookup.vue";    

    const props = defineProps({
        filter: {type: String, default: ""},
        lease_ID: Number,
        disableContextFilter: {type: Boolean, default: false},
        loadDataObject: Object,
        roomsDataObject: Object
    });

    const dsRooms = props.roomsDataObject;
    const roomGrid = ref();
    window["dsRooms"] = dsRooms;
    window["roomGrid"] = roomGrid;

    /* DATASOURCES */
    const dsConditionLevelLkp = getOrCreateDataObject({
        "id": "dsConditionLevelLkp" + "_" + crypto.randomUUID(),
        "viewName": "aviw_Assets_ConditionLevels",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "NameT"},{"name": "FCMRateFactor", "sortOrder": 1, "sortDirection": "desc"}]
    });

    const dsAreaTypeLkp = getOrCreateDataObject({
        "id": "dsAreaTypeLkp" + "_" + crypto.randomUUID(),
        "viewName": "atbv_Assets_AreaTypes",
        "distinctRows": false,
        "uniqueTable": "",
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "Name"},{"name": "IsLeasable", "type": "boolean"}]
    });

    const dsUnitLkp = getOrCreateDataObject({
        "id": "dsUnitLkp" + "_" + crypto.randomUUID(),
        "viewName": "sviw_System_Units",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "whereClause": "NameOriginal IN ('m2','%','Ea.') AND Closed IS NULL",
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "Name","sortOrder": 2,"sortDirection": "asc"},{"name": "SortOrder","sortOrder": 1,"sortDirection": "asc"},{"name": "NameOriginal"}]
    });

    const dsModels = getOrCreateDataObject({
        "id": "dsModels" + "_" + crypto.randomUUID(),
        "viewName": "aviw_BIM_Models",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "whereClause": "MarkedForDeletion=0",
        "filterString": null,
        "disableAutoLoad": true,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "OrgUnit_ID"},{"name": "Name"},{"name": "Longitude"},{"name": "Latitude"},{"name": "MarkedForDeletion"},{"name": "Description"},{"name": "StoreyFragmentDef_ID"},{"name": "IdPath"},{"name": "CurrentRevision_ID"}]
    });

    const dsClassificationLkp = getOrCreateDataObject({
        "id": "dsClassificationLkp" + "_" + crypto.randomUUID(),
        "viewName": "aviw_Assets_ClassificationsItems",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 500,
        "dynamicLoading": false,
        "whereClause": "Level=3 AND IsCurrent=1",
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "Name"},{"name": "Title"},{"name": "NamePath"},{"name": "TitlePath"},{"name": "Parent"},{"name": "Type"},{"name": "Classification"},{"name": "Classification_ID"},{"name": "Level"},{"name": "ClassificationsItem0"},{"name": "ClassificationsItem1"},{"name": "ClassificationsItem2"},{"name": "ClassificationsItem3"},{"name": "ID"},{"name": "ClassificationsItem0_ID"},{"name": "ClassificationsItem1_ID"},{"name": "ClassificationsItem2_ID"},{"name": "ClassificationsItem3_ID"},{"name": "IsCurrent"}]
    });

    const dsRateCategoryLkp = getOrCreateDataObject({
        "id": "dsRateCategoryLkp" + "_" + crypto.randomUUID(),
        "viewName": "atbv_Assets_RateCategories",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "Name"},{"name": "Rate", "type": "number", "sortOrder": 1}]
    });

    /* PROCEDURES */
    const procAddObjectsToLease = getOrCreateProcedure({id : "procAddObjectsToLease", procedureName : "astp_Lease_AddObjectsToLease"});

    /* VARIABLES */
    const vShow = ref(false);
    const vBimGroupByField = ref(null);
    const vBimGroupByFieldVal = ref(null);
    const vGroupColors = ref(null);
    const vRoomStatusDate = ref(null);

    /* VUE */
    watch(() => props.filter, (first, second) => {
        updateWhereClause();
    });

    onMounted(() => {
        props.disableContextFilter ? null : dsRooms.enableContextFilter(); 
        vRoomStatusDate.value = new Date();
        updateWhereClause();
    });

    /* FUNCTIONS */
    async function toggleBIMViewer() {
        if(dsModels.current?.ID) {
            vShow.value = !vShow.value;
        } else {
            alert($t('Please select a BIM-model'), ToastType.Warning);
        }
    }
 
    function updateWhereClause() {
        dsRooms.recordSource.whereClause = "Date='" + (vRoomStatusDate.value.getUTCFullYear().toString() + String("0" + (vRoomStatusDate.value.getUTCMonth()+1)).slice(-2) + String("0" + vRoomStatusDate.value.getUTCDate()).slice(-2)) + "'" + (props.filter ? " AND " + props.filter : "");
        dsRooms.load();  
    }

    async function addSelectedRoomsToLease() {
        try {
            const vData = [];
            toRaw(dsRooms.selectionControl.selectedUniqueKeys).forEach(function name(params) { vData.push( [params] ); })
            const selectedObjects   = ref({
                OrgUnit_ID : props.lease_ID,
                Object_PrimKeys : vData
            });
            await procAddObjectsToLease.execute( selectedObjects.value );

            dsRooms.selectionControl.selectAll(false);
            props.loadDataObject ? props.loadDataObject.load() : null;
            alert( $t("Rooms added to lease"), ToastType.Info, { autohide: true});
        }
        catch (_) { }
    }

    props.disableContextFilter ? null : dsModels.enableContextFilter();
    dsRooms.filterObject.setColumnDistinctOptions( "ClassificationsItem2", {viewName : "aviw_Assets_ClassificationsItems", distinctColumn : "CodeAndTitle", existsBinding : "T1.ID = T2.ClassificationsItem2_ID"});
    dsClassificationLkp.filterObject.setColumnDistinctOptions( "ClassificationsItem2", {viewName : "aviw_Assets_ClassificationsItems", distinctColumn : "CodeAndTitle", existsBinding : "T1.ID = T2.ClassificationsItem2_ID"});
    dsClassificationLkp.filterObject.setColumnDistinctOptions( "ClassificationsItem3", {viewName : "aviw_Assets_ClassificationsItems", distinctColumn : "Title", existsBinding : "T1.ID = T2.ClassificationsItem3_ID"});
</script>

<template>
    <ORowContainer app-container>
        <ORowContainer height="100%">
            <!-- Rooms Grid -->
            <ODataGrid :dataObject="dsRooms" :header-title="$t('Rooms')" :detailIframe="`/nt/room?ID=${dsRooms.current.ID}&HideNav=true`" collapseGridMenu ref="roomGrid">
                <template #cardheader>     
                    <OColContainer class="m-3 align-items-center gap-2">       
                        <div class="col-auto">            
                            <ODataLookup :dataObject="dsModels" class="form-control form-control-sm" :placeholder="$t('Select BIM-model...')" :value="dsModels.current.Name">                
                                <OColumn field="ID" width="80"/>                
                                <OColumn field="Name" width="200"/>            
                            </ODataLookup>        
                        </div>        
                        <button class="btn btn-outline-primary btn-sm" @click="toggleBIMViewer">            
                            <span v-if="!vShow">{{ $t( "Show" ) }}</span>            
                            <span v-if="vShow">{{ $t( "Hide" ) }}</span>            
                            {{ $t( "BIM Viewer" ) }}        
                        </button>
                        <div class="col-auto">            
                            <ODatePicker class="form-control form-control-sm" id="roomStatusDate" format="Short Date" v-model="vRoomStatusDate" :callback="updateWhereClause" :showInputIcon="true" :clearable="false"/>        
                        </div>
                        <div v-if="props.lease_ID">
                            <button class="btn btn-outline-primary btn-sm" @click="addSelectedRoomsToLease">
                                {{ $t( "Add selected rooms to lease" ) }} ({{ dsRooms.selectionControl.selectedUniqueKeys.size }})
                            </button>
                        </div>
                    </OColContainer>
                </template>

                <OColumn field="ID" width="80" sortable :header-title="$t('ID')" :header-name="$t('ID')" v-slot="{ row }" pinned="left">    <a :href="`/nt/room?ID=${ row.ID }`" target="_blank"> {{ row.ID }} </a></OColumn>
                <OColumn field="Created" width="110" hide sortable/>
                <OColumn field="Updated" width="110" hide sortable/>
                <OColumn field="OrgUnit" bulkUpdate editable distinct width="200" sortable v-slot:editor="{modelValue: row}">
                    <OOrgunitsLookup :bind="sel=>{row.OrgUnit_ID = sel.ID; row.OrgUnit = sel.Name; row.AccessIdPath = sel.AccessIdPath; row.OrgUnitIdPath = sel.IdPath}">            
                        <template #orgunit>
                            <input type="text" id="orgUnitInput" v-model="row.OrgUnit">            
                        </template>    
                    </OOrgunitsLookup>
                </OColumn>  
                <OColumn field="Name" editable width="160" :header-name="$t('Name')" pinned="left" sortable/>
                <OColumn field="Description" editable width="180" :header-name="$t('Description')" sortable pinned="left"/>
                <OColumn field="Location" width="160" :header-name="$t('Location')" sortable distinct v-slot:editor="{row}" bulkUpdate editable> 
                    <ObjectLookup :bind="sel=>{ row.Location = sel.Name; row.Location_ID = sel.ID}" :objectsWhereClause="'IsLocation=1'">
                        <template #target="{ target }">
                            <input :ref="target" type="text" v-model="row.Location"> 
                        </template>
                    </ObjectLookup>
                </OColumn>
                <OColumn field="Building" width="160" :header-name="$t('Building')" sortable distinct v-slot:editor="{modelValue: row}" bulkUpdate editable> 
                    <ObjectLookup :bind="sel=>{ row.Building = sel.Name; row.Building_ID = sel.ID}" :objectsWhereClause="'IsBuilding=1'">
                        <template #target="{ target }">
                            <input :ref="target" type="text" v-model="row.Building"> 
                        </template>
                    </ObjectLookup>
                </OColumn>
                <OColumn field="Storey" width="160" :header-name="$t('Storey')" sortable distinct v-slot:editor="{modelValue: row}" bulkUpdate editable> 
                    <ObjectLookup :bind="sel=>{ row.Storey = sel.Name; row.Storey_ID = sel.ID}">
                        <template #target="{ target }">
                            <input :ref="target" type="text" v-model="row.Storey"> 
                        </template>
                    </ObjectLookup>
                </OColumn>
                <OColumn field="Wing" width="160" :header-name="$t('Wing')" sortable distinct v-slot:editor="{modelValue: row}" bulkUpdate editable> 
                    <ObjectLookup :bind="sel=>{ row.Wing = sel.Name; row.Wing_ID = sel.ID}">
                        <template #target="{ target }">
                            <input :ref="target" type="text" v-model="row.Wing"> 
                        </template>
                    </ObjectLookup>
                </OColumn>
                <OColumn field="Component" width="160" :header-name="$t('Component')" hide sortable/> 
                <OColumn field="AssetCondition" editable width="120" :header-name="$t('Condition Level')" distinct v-slot:editor="{ row }" bulkUpdate>    
                    <ODataLookup :data-object="dsConditionLevelLkp" disableRecent v-model="row.AssetCondition" :bind="sel => { row.AssetCondition = sel.NameT; row.AssetCondition_ID = sel.ID; }">
                        <OColumn field="ID" width="80" :header-title="$t('ID')" :header-name="$t('ID')" />        
                        <OColumn field="NameT" width="200" :header-title="$t('Name')" :header-name="$t('Name')" />        
                        <OColumn field="FCMRateFactor" width="110" :header-title="$t('Factor')" :header-name="$t('Factor')" />    
                    </ODataLookup>
                </OColumn>
                <OColumn field="DesignedCapacity" bulkUpdate editable width="140" :header-name="$t('Designed Capacity')" class="text-end" summaryAggregate="SUM"/>
                <OColumn field="ActualCapacity" bulkUpdate editable width="140" :header-name="$t('Actual Capacity')" class="text-end" summaryAggregate="SUM"/>
                <OColumn field="ClassificationsItem" sortable editable width="110" :header-name="$t('Classification')" distinct v-slot:editor="{ row }" bulkUpdate>    
                    <ODataLookup v-model="row.ClassificationsItem" :data-object="dsClassificationLkp" :bind="sel => { row.ClassificationsItem_ID = sel.ID; row.ClassificationsItem = sel.Name; }">        
                        <OColumn field="ClassificationsItem0" width="250" :header-name="$t('Classification 1')" />        
                        <OColumn field="ClassificationsItem1" width="250" :header-name="$t('Classification 2')" />        
                        <OColumn field="ClassificationsItem2" width="250" :header-name="$t('Classification 3')"/>        
                        <OColumn field="ClassificationsItem3" width="250" :header-name="$t('Classification 4')" />    
                    </ODataLookup>
                </OColumn>
                <OColumn field="ClassificationsItem0" width="140" :header-name="$t('Main Function')" :cellTitle="row => row.ClassificationsItem0Description"/>
                <OColumn field="ClassificationsItem1" width="140" :header-name="$t('Partial Function')" :cellTitle="row => row.ClassificationsItem1Description"/>
                <OColumn field="ClassificationsItem2" width="140" :header-name="$t('Room Name')" :cellTitle="row => row.ClassificationsItem2Description"/>
                <OColumn field="ClassificationsItem3" width="140" :header-name="$t('Room Specification')" :cellTitle="row => row.ClassificationsItem3Description"/>
                <OColumn field="GLN" editable width="90" :header-name="$t('GLN')" hide/>
                <OColumn field="GTIN" editable width="90" :header-name="$t('GTIN')" hide/>
                <OColumn field="GIAI" editable width="90" :header-name="$t('GIAI')" hide/>
                <OColumn field="GRAI" editable width="90" :header-name="$t('GRAI')" hide/>
                <OColumn field="GrossArea" format="1 234.12" bulkUpdate editable width="110" :header-name="$t('Gross Area')" class="text-end" summaryAggregate="SUM"/>
                <OColumn field="NetArea" bulkUpdate editable width="110" format="1 234.12" :header-name="$t('Net Area')" class="text-end" summaryAggregate="SUM"/>
                <OColumn field="AreaType" editable bulkUpdate width="110" :header-name="$t('Area Type')" distinct v-slot:editor="{ row }">
                    <ODataLookup v-model="row.AreaType" :data-object="dsAreaTypeLkp" :bind="sel => { row.AreaType_ID = sel.ID; row.AreaType = sel.Name }">
                        <OColumn field="ID" width="80" :header-title="$t('ID')" :header-name="$t('ID')" />
                        <OColumn field="Name" width="300" :header-title="$t('Name')" :header-name="$t('Name')" />
                        <OColumn field="IsLeasable" width="90" :header-name="$t('Is Leasable')" />
                    </ODataLookup>
                </OColumn> 
                <OColumn field="IsLeasable" width="90" :header-name="$t('Is Leasable')"/>
                <OColumn field="PriceModelUnit" bulkUpdate editable width="125" :header-name="$t('Price Model Unit')" distinct v-slot:editor="{ row }">    
                    <ODataLookup v-model="row.PriceModelUnit" :data-object="dsUnitLkp" :bind="sel => { row.PriceModelUnit = sel.Name; }">        
                        <OColumn field="Name" width="300" :header-title="$t('Name')" :header-name="$t('Name')" />    
                    </ODataLookup>
                </OColumn> 
                <OColumn field="RateCategory" width="150" :header-name="$t('Rate Category Name')" bulkUpdate editable distinct v-slot:editor="{ row }">
                    <ODataLookup v-model="row.RateCategory" :data-object="dsRateCategoryLkp" :bind="sel => { row.RateCategory_ID = sel.ID; row.RateCategory = sel.Name; row.RateCategoryRate = sel.Rate; }" disableRecent>
                        <OColumn field="ID" width="80" :header-title="$t('ID')" :header-name="$t('ID')" />    
                        <OColumn field="Name" width="200" :header-title="$t('Name')" :header-name="$t('Name')" />    
                        <OColumn field="Rate" width="100" :header-title="$t('Rate')" :header-name="$t('Rate')" format="1 234"/>    
                    </ODataLookup>
                </OColumn>
                <OColumn field="RateCategoryRate" width="110" :header-name="$t('Rate Category')" format="1 234" class="text-end" summaryAggregate="SUM"/>
                <OColumn field="IsLeased" width="80" :header-name="$t('Is Leased')"/> 
                <OColumn field="LeaseHolders" width="150" :header-name="$t('Lease Holders')"/>
                <OColumn field="LeaseLastStartDate" width="150" :header-name="$t('Lease Start Date')" sortable format="Short Date"/>
                <OColumn field="LeaseLastEndDate" width="150" :header-name="$t('Lease End Date')" sortable format="Short Date"/>
                <OColumn field="NetAreaUsed" format="1 234.12" width="150" :header-name="$t('Net Area Used')" class="text-end" summaryAggregate="SUM"/>
                <OColumn field="CapacityUsed" format="1 234.12" width="150" :header-name="$t('Capacity Used')" class="text-end" summaryAggregate="SUM"/>
                <OColumn field="NetAreaUsedPercentage" width="150" :header-name="$t('Net Area Used %')" format="%" class="text-end"/>
                <OColumn field="Availability" width="150" :header-name="$t('Availability %')" format="%" class="text-end"/>
                <OColumn field="SuppliedBy" width="150" :header-name="$t('Contract (Supplied By)')" :header-title="$t('Supplied By Contract')" v-slot:editor="{modelValue: row}" bulkUpdate editable distinct sortable>
                    <OOrgunitsLookup :bind="sel=>{row.SuppliedBy_ID = sel.ID; row.SuppliedBy = sel.Name;}">            
                        <template #orgunit> 
                            <input type="text" id="orgUnitInput" v-model="row.SuppliedBy">
                        </template>    
                    </OOrgunitsLookup>
                </OColumn>
                <OColumn field="ContractActualEnd" width="150" :header-name="$t('Contract Actual End')" format="Short Date"/>  
            </ODataGrid>
        </ORowContainer>

        <!-- BIM-viewer -->
        <OSizerPanel :class="vShow ? '' : 'd-none'" height="60%" maxHeight="90%" collapse id="main-sizerpanel-1">
            <ORowContainer class="mt-2">
                <div class="row mb-1">    
                    <OColContainer class="align-items-center gap-2">
                        <div class="dropdown" style="display: contents;">
                            <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span v-if="vBimGroupByField">{{ $t('Group By') }}: {{ vBimGroupByField }}</span>
                                <span v-else>{{ $t('No grouping') }}</span>            
                            </button>            
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = null; vBimGroupByFieldVal = null;">{{ $t('No grouping') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Classification 1'); vBimGroupByFieldVal = 'ClassificationsItem0Title';">{{ $t('Classification 1') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Classification 2'); vBimGroupByFieldVal = 'ClassificationsItem1Title';">{{ $t('Classification 2') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Classification 3'); vBimGroupByFieldVal = 'ClassificationsItem2Title';">{{ $t('Classification 3') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Classification 4'); vBimGroupByFieldVal = 'ClassificationsItem3Title';">{{ $t('Classification 4') }}</a></li>
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Condition Level'); vBimGroupByFieldVal = 'AssetCondition';">{{ $t('Condition Level') }}</a></li>
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Lease Holders'); vBimGroupByFieldVal = 'LeaseHolders';">{{ $t('Lease Holders') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Storey'); vBimGroupByFieldVal = 'Storey';">{{ $t('Storey') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Area Type'); vBimGroupByFieldVal = 'AreaType';">{{ $t('Area Type') }}</a></li>            
                            </ul>        
                        </div>
                        <div class="ml-4" v-html="vGroupColors"></div>    
                    </OColContainer>
                </div>
                <BIMViewer :vDS="dsRooms" :vModelID="dsModels.current.ID" :vFieldName="vBimGroupByFieldVal" :vFilter="(props.disableContextFilter ? '' : `AccessIdPath LIKE '${context.idPath}%' AND `) + dsRooms.recordSource.whereClause + (dsRooms.recordSource.filterString ? ' AND ' + dsRooms.recordSource.filterString : '')" :vSelectedObjectIDs="JSON.stringify(dsRooms.selectionControl.selectedRows.map(e => {return {ID: e.ID}}))" @group-colors-added="(n) => vGroupColors = n"/>
            </ORowContainer>
        </OSizerPanel>
    </ORowContainer>
</template>